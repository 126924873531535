import React from 'react';
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";

import Layout from '../../layout';

class ApplicationForm extends React.Component {

  componentDidMount() {
    const ifr = document.getElementById("JotFormIFrame-92446466580162");
    if(window.location.href && window.location.href.indexOf("?") > -1) {
      const get = window.location.href.substr(window.location.href.indexOf("?") + 1);
      if(ifr && get.length > 0) {
        let { src } = ifr;
        src = src.indexOf("?") > -1 ? `${src}&${get}` : `${src}?${get}`;
        ifr.src = src;
      }
    }
      const handleIFrameMessage = (e) => {
      let iframe;
      if (typeof e.data === 'object') {
        return;
      }
      const args = e.data.split(":");
      if (args.length > 2) {
        iframe = document.getElementById(`JotFormIFrame-${args[(args.length - 1)]}`);
      } else {
        iframe = document.getElementById("JotFormIFrame");
      }
      if (!iframe) {
        return;
      }

      let src = args[1];
      const script = document.createElement('script');
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = `${args[1]}px`;
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = `${window.innerHeight}px`;
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          if (args.length > 3) {
            src = `${args[1]}:${args[2]}`;
          }
          script.src = src;
          script.type = 'text/javascript';
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if (window.document.exitFullscreen)
            window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)
            window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)
            window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)
            window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)
            window.document.msExitFullscreen();
          break;
        default:
          break;
        }
        const isJotForm = e.origin.indexOf("jotform") > -1;
        if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
          const urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
          iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
        }
      };
      if (window.addEventListener) {
        window.addEventListener("message", handleIFrameMessage, false);
      } else if (window.attachEvent) {
        window.attachEvent("onmessage", handleIFrameMessage);
      }
  }

  render() {
    return (
      <Layout>
        <Card>
          <CardTitle title="Application form" />
          <iframe
            id="JotFormIFrame-92446466580162"
            allowFullScreen
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/92446466580162"
            title="Admission Form"
            frameBorder="0"
            style={{width: '1px', minWidth: '100%', height: '75vh', border: 'none'}}
            scrolling="yes"
          />
        </Card>
      </Layout>
    )
  }
}

export default ApplicationForm;
